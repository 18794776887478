var db = {
  save: function save(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
  },
  get: function get(key) {
    var defaultValue = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    return JSON.parse(localStorage.getItem(key)) || defaultValue;
  },
  remove: function remove(key) {
    localStorage.removeItem(key);
  },
  clear: function clear() {
    var loginType = localStorage.getItem('loginType');
    localStorage.clear();
    sessionStorage.clear();
    if (loginType) {
      localStorage.setItem('loginType', loginType);
    }
  }
};
export default db;