var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "navbar" },
        [
          _c("hamburger", {
            staticClass: "hamburger-container",
            attrs: {
              id: "hamburger-container",
              "is-active": _vm.sidebar.opened,
            },
            on: { toggleClick: _vm.toggleSideBar },
          }),
          _vm._v(" "),
          _c("breadcrumb", {
            staticClass: "breadcrumb-container",
            attrs: { id: "breadcrumb-container" },
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "right-menu" },
            [
              _vm._l(_vm.validRoles, function (role) {
                return _c("el-tag", { key: role.id }, [
                  _vm._v(_vm._s(role.roleNameZh)),
                ])
              }),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticClass: "right-menu-item button",
                  on: { click: _vm.showPermissionApplyDialog },
                },
                [_vm._v("申请权限")]
              ),
              _vm._v(" "),
              _vm.device !== "mobile"
                ? [
                    _c("screenfull", {
                      staticClass: "right-menu-item hover-effect",
                      attrs: { id: "screenfull" },
                    }),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-dropdown",
                {
                  staticClass: "avatar-container right-menu-item hover-effect",
                  attrs: { trigger: "click" },
                },
                [
                  _c("div", { staticClass: "avatar-wrapper" }, [
                    _c("img", {
                      staticClass: "user-avatar",
                      attrs: { src: _vm.avatar, alt: "avatar" },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "user-name" }, [
                      _vm._v(_vm._s(_vm.username)),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-dropdown-menu",
                    { attrs: { slot: "dropdown" }, slot: "dropdown" },
                    [
                      _c(
                        "router-link",
                        { attrs: { to: "/profile/index" } },
                        [
                          _c("el-dropdown-item", [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t("navbar.profile")) +
                                "\n            "
                            ),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("el-dropdown-item", [
                        _c(
                          "span",
                          {
                            staticStyle: { display: "block" },
                            on: { click: _vm.setting },
                          },
                          [_vm._v("设置")]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("el-dropdown-item", { attrs: { divided: "" } }, [
                        _c(
                          "span",
                          {
                            staticStyle: { display: "block" },
                            on: { click: _vm.deleteCache },
                          },
                          [_vm._v("清除缓存")]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("el-dropdown-item", { attrs: { divided: "" } }, [
                        _c(
                          "span",
                          {
                            staticStyle: { display: "block" },
                            on: { click: _vm.logout },
                          },
                          [_vm._v("退出登录")]
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "申请权限",
            visible: _vm.dialog.permissionApply.visible,
            width: "50%",
            "append-to-body": true,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.dialog.permissionApply, "visible", $event)
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "registerForm",
              attrs: {
                model: _vm.dialog.permissionApply.form,
                "label-width": "80px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "部门" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择部门" },
                      on: { change: _vm.departmentSelected },
                      model: {
                        value: _vm.dialog.permissionApply.form.departmentId,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.dialog.permissionApply.form,
                            "departmentId",
                            $$v
                          )
                        },
                        expression: "dialog.permissionApply.form.departmentId",
                      },
                    },
                    _vm._l(_vm.departmentList, function (department) {
                      return _c("el-option", {
                        key: department.id,
                        attrs: {
                          label: department.deptName,
                          value: department.id,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "角色" } },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      model: {
                        value: _vm.dialog.permissionApply.form.roleIds,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.dialog.permissionApply.form,
                            "roleIds",
                            $$v
                          )
                        },
                        expression: "dialog.permissionApply.form.roleIds",
                      },
                    },
                    _vm._l(
                      _vm.dialog.permissionApply.roleOptions,
                      function (role) {
                        return _c(
                          "el-checkbox",
                          {
                            key: role.id,
                            attrs: {
                              disabled: _vm.hasRole(role.id),
                              label: role.id,
                              border: "",
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(role.roleNameZh) +
                                " (" +
                                _vm._s(role.description) +
                                ")"
                            ),
                          ]
                        )
                      }
                    ),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.applyRole },
                    },
                    [_vm._v("提交")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }