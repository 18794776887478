//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { AppMain, Navbar, Settings, Sidebar, TagsView } from "./components";
import RightPanel from '@/components/RightPanel';
import ResizeMixin from "./mixin/ResizeHandler";
export default {
  name: 'Layout',
  components: {
    AppMain: AppMain,
    Navbar: Navbar,
    Settings: Settings,
    Sidebar: Sidebar,
    TagsView: TagsView,
    RightPanel: RightPanel
  },
  mixins: [ResizeMixin],
  computed: {
    sidebar: function sidebar() {
      return this.$store.state.setting.sidebar;
    },
    device: function device() {
      return this.$store.state.setting.device;
    },
    showSettings: function showSettings() {
      return this.$store.state.setting.settingBar.opened;
    },
    needTagsView: function needTagsView() {
      return this.$store.state.setting.multipage;
    },
    fixedHeader: function fixedHeader() {
      return this.$store.state.setting.fixHeader;
    },
    classObj: function classObj() {
      return {
        hideSidebar: !this.sidebar.opened,
        openSidebar: this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation,
        mobile: this.device === 'mobile'
      };
    }
  },
  methods: {
    handleClickOutside: function handleClickOutside() {
      this.$store.commit('setting/closeSidebar', {
        withoutAnimation: false
      });
    }
  }
};