import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
import "core-js/modules/es6.regexp.to-string";
import axios from 'axios';
import Vue from 'vue';
import { getAccessToken } from '@/utils/auth';
import { isNullOrEmpty } from '@/utils/index';
import router from '@/router';
import { encrypt } from '@/api/Consultant/security';
import db from '@/utils/localstorage';
var success = 200;

/**
 * 封装axios
 */
var request = new axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 15000,
  validateStatus: function validateStatus(status) {
    return status === success;
  }
});

// 请求拦截器
request.interceptors.request.use(function (config) {
  config.headers['X-zs-t'] = encrypt(parseInt(new Date().getTime() / 1000).toString()); // 用于通过反爬虫校验的请求头
  return addToken(config);
}, function (err) {
  console.log(err);
});

// 响应拦截器
request.interceptors.response.use(function (res) {
  if (res.data.code === 10006) {
    db.clear();
    router.push('/login');
  }
  return res;
}, function (err) {
  if (err.code === 'ECONNABORTED' || err.message === 'Network Error' || err.message.includes('timeout')) {
    return Promise.reject('请求超时');
  }
  if (!err || !err.response || err.response.status === 204) return err;
  console.log('响应拦截器异常, err.response:', err.response);
  switch (err.response.status) {
    case 400:
      if (err.response.data.code === 20013) {
        db.clear();
        router.push('/login');
      }
      break;
    case 401:
      Vue.prototype.$message({
        message: err.response.data.message,
        type: 'error',
        duration: 5000
      });
      db.clear();
      router.push('/login');
      break;
    case 403:
      Vue.prototype.$message({
        message: '拒绝访问',
        type: 'error'
      });
      break;
    case 404:
      Vue.prototype.$message({
        message: '请求的资源未找到',
        type: 'error'
      });
      break;
    case 503:
      Vue.prototype.$message({
        message: '服务未启动',
        type: 'error'
      });
      break;
    default:
      Vue.prototype.$message({
        message: '未知错误',
        type: 'error'
      });
  }
  return Promise.reject(err);
});
function addToken(config) {
  var token = getAccessToken();
  var Authorization = config.headers.Authorization;
  if (!config.isNotToken) {
    if (!isNullOrEmpty(Authorization)) {
      config.headers['Authorization'] = Authorization;
    } else if (isNullOrEmpty(Authorization) && token) {
      config.headers['Authorization'] = 'bearer ' + token;
    }
  }
  return config;
}
export default request;