import request from "../request";
var authApi = {
  // 校验token
  checkToken: function checkToken(params) {
    return request.post('/auth/oauth/check_token', null, {
      params: params,
      headers: {
        Authorization: 'Basic enMtY21zOjEyMzQ1Ng=='
      }
    });
  },
  // 登录请求
  login: function login(params) {
    return request.post('/auth/oauth/token', null, {
      params: params,
      headers: {
        Authorization: 'Basic enMtY21zOjEyMzQ1Ng=='
      },
      isNotToken: true
    });
  },
  // 登陆后获得用户信息
  getPrincipal: function getPrincipal() {
    return request.get('/auth/account/principal');
  },
  // 通过手机号获取短信验证码
  getSmsCode: function getSmsCode(mobile) {
    return request.get("/auth/sms/login?mobile=".concat(mobile));
  },
  // 登陆后获得用户的动态路由
  getUserRouterByUserId: function getUserRouterByUserId(userId) {
    return request.get("/system/menus/router/".concat(userId));
  },
  // 通过token注销令牌
  revokeToken: function revokeToken(token) {
    return request.delete("/auth/oauth/token?token=".concat(token), {
      headers: {
        Authorization: 'Basic enMtY21zOjEyMzQ1Ng=='
      }
    });
  },
  // 通过userId注销令牌
  revokeId: function revokeId(userId) {
    return request.delete("/auth/token/kick-out?userId=".concat(userId));
  },
  // 删除用户
  deleteUser: function deleteUser(userId, currHour) {
    return request.delete("/auth/cms/user/".concat(userId, "/cancel?currHour=").concat(currHour));
  }
};
export default authApi;