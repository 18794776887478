import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import _defineProperty from "/var/jenkins_home/workspace/zs-cloud-admin/node_modules/.pnpm/@babel+runtime-corejs2@7.24.5/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import Vue from 'vue';
import Router from 'vue-router';
import Layout from '@/layout';
import AppMain from '@/layout/components/AppMain';
import db from '@/utils/localstorage';
import store from '@/store/index';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import authApi from "../api/auth/authApi";
import { isNullOrEmpty } from '@/utils/index';
Vue.use(Router);

// 无需权限路由信息
var constRouter = [{
  path: '/redirect',
  component: Layout,
  hidden: true,
  children: [{
    path: '/redirect/:path*',
    component: function component(resolve) {
      return require(['@/views/redirect/index'], resolve);
    }
  }]
}, {
  path: '/login',
  name: '登录页',
  component: function component(resolve) {
    return require(['@/views/login'], resolve);
  }
}, {
  path: '/iframe',
  name: 'iframe',
  component: function component(resolve) {
    return require(['@/views/iframe'], resolve);
  }
}, {
  path: '/',
  component: Layout,
  redirect: '/dashboard',
  children: [{
    path: 'dashboard',
    component: function component(resolve) {
      return require(['@/views/dashboard/index.vue'], resolve);
    },
    name: 'Dashboard',
    meta: {
      title: 'dashboard',
      icon: 'dashboard',
      affix: true
    }
  }]
}, {
  path: '/profile',
  component: Layout,
  redirect: '/profile/index',
  hidden: true,
  children: [{
    path: 'index',
    component: function component(resolve) {
      return require(['@/views/profile/index'], resolve);
    },
    name: 'Profile',
    meta: {
      title: 'profile',
      icon: 'user',
      noCache: true
    }
  }]
}, {
  path: '/lowCode',
  component: Layout,
  redirect: '/lowCode/index',
  hidden: true,
  children: [{
    path: 'index',
    component: function component(resolve) {
      return require(['@/views/lowCode/index'], resolve);
    },
    name: 'lowCode',
    meta: {
      title: 'lowCode',
      icon: 'user',
      noCache: true
    }
  }]
}];
var router = new Router({
  scrollBehavior: function scrollBehavior() {
    return {
      y: 0
    };
  },
  routes: constRouter
});
var whiteList = ['/login'];

// 登录时返回的路由
var asyncRouter;

// 导航守卫，渲染动态路由
router.beforeEach(function (to, from, next) {
  NProgress.start();
  if (whiteList.indexOf(to.path) !== -1) {
    next();
  } else {
    var token = db.get('ACCESS_TOKEN');
    var user = db.get('USER', null);
    // storage里的路由信息
    var userRouter = db.get('USER_ROUTER', '');
    // 判断storage里面是否已经保存用户信息
    if (token && token.length && !isNullOrEmpty(user)) {
      if (!asyncRouter) {
        if (!userRouter) {
          authApi.getUserRouterByUserId(user.zsUser.id).then(function (response) {
            asyncRouter = response.data.data.concat([{
              path: '*',
              name: '404',
              component: 'error-page/404',
              hidden: false,
              alwaysShow: false
            }]);
            store.commit('account/setRoutes', asyncRouter);
            go(to, next);
          }).catch(function () {
            next('/login');
          });
        } else {
          asyncRouter = userRouter;
          go(to, next);
        }
      } else {
        next();
      }
    } else {
      if (to.path === '/login') {
        next();
      } else {
        NProgress.done();
        next('/login');
      }
    }
  }
});
router.afterEach(function () {
  NProgress.done();
});
function go(to, next) {
  asyncRouter = filterAsyncRouter(asyncRouter);
  router.addRoutes(asyncRouter);
  next(_objectSpread(_objectSpread({}, to), {}, {
    replace: true
  }));
}

/**
 * 将后端传递过来的router.component从字符串解析成组件对象
 */
function filterAsyncRouter(routes) {
  return routes.filter(function (route) {
    var component = route.component;
    if (component) {
      if (route.component === 'Layout') {
        route.component = Layout;
      } else if (route.component === 'AppMain') {
        route.component = AppMain;
      } else {
        route.component = function (resolve) {
          return require(["@/views/".concat(component)], resolve);
        };
      }
      if (route.children && route.children.length) {
        route.children = filterAsyncRouter(route.children);
      }
      return true;
    }
  });
}
export default router;