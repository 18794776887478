import request from "../request";
var roleApi = {
  getRoleById: function getRoleById(roleId) {
    return request.get("/system/roles/".concat(roleId));
  },
  /**
   * @param queryParams: key pageNum pageSize sort order
   */
  getRoleList: function getRoleList(queryParams) {
    return request.get('/system/roles', {
      params: queryParams
    });
  },
  /**
   * @param role 角色对象
   * @param menuIds 角色的权限id列表(全量式)
   */
  addRole: function addRole(role, menuIds) {
    role.menuIds = menuIds;
    return request.post("/system/roles", role);
  },
  updateRole: function updateRole(role, menuIds) {
    role.menuIds = menuIds;
    return request.put("/system/roles/".concat(role.id), role);
  },
  // 角色绑定权限(全量式)
  bindMenuByWholeQuantity: function bindMenuByWholeQuantity(roleId, menuIdList) {
    return request.post("/system/roles/".concat(roleId, "/bindBatch/menus"), menuIdList);
  },
  // 获得角色的权限id列表
  getBoundMenuIdList: function getBoundMenuIdList(roleId) {
    return request.get("/system/roles/".concat(roleId, "/menus"));
  },
  deleteById: function deleteById(roleId) {
    return request.delete("/system/roles/".concat(roleId));
  },
  getRolesByDepartmentId: function getRolesByDepartmentId(departmentId) {
    return request.get("/system/roles/departmentId/".concat(departmentId));
  },
  applyRole: function applyRole(permissionApplyForm) {
    return request.post("/system/roles/apply-role", permissionApplyForm);
  }
};
export default roleApi;