import "core-js/modules/es6.object.keys";
import "core-js/modules/web.dom.iterable";
import _Element2 from "element-ui/lib/theme-chalk/index.css";
import _Element from "element-ui/lib";
import "/var/jenkins_home/workspace/zs-cloud-admin/node_modules/.pnpm/core-js@2.6.12/node_modules/core-js/modules/es6.array.iterator.js";
import "/var/jenkins_home/workspace/zs-cloud-admin/node_modules/.pnpm/core-js@2.6.12/node_modules/core-js/modules/es6.promise.js";
import "/var/jenkins_home/workspace/zs-cloud-admin/node_modules/.pnpm/core-js@2.6.12/node_modules/core-js/modules/es6.object.assign.js";
import "/var/jenkins_home/workspace/zs-cloud-admin/node_modules/.pnpm/core-js@2.6.12/node_modules/core-js/modules/es7.promise.finally.js";
import Vue from 'vue';
import 'normalize.css/normalize.css'; // a modern alternative to CSS resets
import '@/styles/index.scss'; // global css
// import '@/styles/element-variables.scss';

import 'element-ui/lib/theme-chalk/index.css';
import App from "./App";
import store from "./store";
import router from "./router";
import i18n from "./lang"; // internationalization
import "./icons"; // icon
import "./utils/error-log"; // error log
Vue.use(_Element);
import ZSMessage from "./utils/message";
Vue.prototype.$message = ZSMessage;
import Vant from 'vant';
import 'vant/lib/index.css';
Vue.use(Vant);

// Echarts（这里引入的是4.X版本的Echarts，因为5.X会有bug，猜测是只支持vue3了）
import echarts from 'echarts';
Vue.prototype.$echarts = echarts;
import * as plugins from "./utils/permissionDirect";
import { hasAuthority } from "./utils/authority";

// 检测是否有版本更新
import "./utils/version-polling";
Object.keys(plugins).forEach(function (key) {
  Vue.use(key);
});

// 挂载权限控制函数
Vue.prototype.hasAuthority = hasAuthority;
Vue.config.productionTip = false;
new Vue({
  el: '#app',
  router: router,
  store: store,
  i18n: i18n,
  render: function render(h) {
    return h(App);
  }
});