exports = module.exports = require("../../node_modules/.pnpm/css-loader@1.0.1_webpack@4.47.0/node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "", ""]);

// exports
exports.locals = {
	"menuText": "rgba(255,255,255,.7)",
	"menuActiveText": "#fff",
	"subMenuActiveText": "#fff",
	"menuBg": "#272c33",
	"menuHover": "#rgba 0,0,0,.1",
	"subMenuBg": "#272c33",
	"subMenuHover": "#rgba 0,0,0,.1",
	"sideBarWidth": "255px",
	"menuTextHover": "#fff"
};