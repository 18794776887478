//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var _require = require("../../../../package.json"),
  _version = _require.version;
export default {
  name: 'SidebarLogo',
  props: {
    collapse: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    logo: function logo() {
      return require('@/assets/logo.jpg');
    },
    version: function version() {
      return _version;
    }
  }
};