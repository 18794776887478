import db from '@/utils/localstorage';
export default {
  namespaced: true,
  state: {
    settingBar: {
      opened: false
    },
    sidebar: {
      opened: db.get('SIDEBAR_STATUS', true),
      withoutAnimation: false
    },
    device: 'desktop',
    sidebarLogo: db.get('SIDEBAR_LOGO', true),
    multipage: db.get('MULTIPAGE', true),
    fixHeader: db.get('FIX_HEADER', true),
    theme: '#1890FF'
  },
  mutations: {
    toggleSidebar: function toggleSidebar(state) {
      state.sidebar.opened = !state.sidebar.opened;
      state.sidebar.withoutAnimation = false;
      if (state.sidebar.opened) {
        db.save('SIDEBAR_STATUS', 1);
      } else {
        db.save('SIDEBAR_STATUS', 0);
      }
    },
    closeSidebar: function closeSidebar(state, withoutAnimation) {
      db.save('SIDEBAR_STATUS', 0);
      state.sidebar.opened = false;
      state.sidebar.withoutAnimation = withoutAnimation;
    },
    openSettingBar: function openSettingBar(state, val) {
      state.settingBar.opened = val;
    },
    setMultipage: function setMultipage(state, multipage) {
      db.save('MULTIPAGE', multipage);
      state.multipage = multipage;
    },
    fixHeader: function fixHeader(state, val) {
      db.save('FIX_HEADER', val);
      state.fixHeader = val;
    },
    toggleDevice: function toggleDevice(state, device) {
      state.device = device;
    },
    setSidebarLogo: function setSidebarLogo(state, val) {
      db.save('SIDEBAR_LOGO', val);
      state.sidebarLogo = val;
    },
    setTheme: function setTheme(state, val) {
      state.theme = val;
    }
  }
};