import "core-js/modules/es7.symbol.async-iterator";
import "core-js/modules/es6.symbol";
import "core-js/modules/es6.string.iterator";
import "core-js/modules/es6.array.from";
import "core-js/modules/es6.function.name";
import "core-js/modules/es6.regexp.to-string";
function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Breadcrumb from '@/components/Breadcrumb';
import Hamburger from '@/components/Hamburger';
import db from '@/utils/localstorage';
import Screenfull from '@/components/Screenfull';
import authApi from '@/api/auth/authApi';
import { isNullOrEmpty } from "../../utils";
import cmsUserApi from "../../api/system/cmsUserApi";
import departmentApi from "../../api/system/departmentApi";
import roleApi from "../../api/system/roleApi";
export default {
  components: {
    Breadcrumb: Breadcrumb,
    Hamburger: Hamburger,
    Screenfull: Screenfull
  },
  data: function data() {
    return {
      dialog: {
        permissionApply: {
          visible: false,
          form: {
            departmentId: null,
            roleIds: []
          },
          roleOptions: []
        }
      },
      departmentList: []
    };
  },
  computed: {
    sidebar: function sidebar() {
      return this.$store.state.setting.sidebar;
    },
    avatar: function avatar() {
      return this.$store.state.account.user.zsUser.avatar;
    },
    username: function username() {
      return this.$store.state.account.user.zsUser.nickname || this.$store.state.account.user.zsUser.mobile || this.$store.state.account.user.zsUser.email || this.$store.state.account.user.zsUser.id;
    },
    device: function device() {
      return this.$store.state.setting.device;
    },
    roles: function roles() {
      return this.$store.state.account.roles || [];
    },
    validRoles: function validRoles() {
      if (this.$store.state.account.roles.filter) {
        return this.$store.state.account.roles.filter(function (role) {
          return role.reviewStatus === 1;
        });
      }
      return [];
    }
  },
  methods: {
    toggleSideBar: function toggleSideBar() {
      this.$store.commit('setting/toggleSidebar');
    },
    setting: function setting() {
      this.$store.commit('setting/openSettingBar', true);
    },
    logout: function logout() {
      this.clean();
    },
    clean: function clean() {
      db.clear();
      location.reload();
    },
    deleteCache: function deleteCache() {
      var _this = this;
      this.$confirm(this.$t('tips.confirmDeleteCache'), this.$t('common.tips'), {
        confirmButtonText: this.$t('common.confirm'),
        cancelButtonText: this.$t('common.cancel'),
        type: 'warning'
      }).then(function () {
        var token = !isNullOrEmpty(db.get('ACCESS_TOKEN')) ? db.get('ACCESS_TOKEN') : '';
        authApi.revokeToken(token); // 注销用户令牌
        db.remove('USER_ROUTER');
        db.remove('PERMISSIONS');
        db.remove('ACCESS_TOKEN');
        _this.$router.push({
          path: '/login'
        });
      }).catch(function () {
        // do nothing
      });
    },
    showPermissionApplyDialog: function showPermissionApplyDialog() {
      var _this2 = this;
      departmentApi.getAllDepartments().then(function (_ref) {
        var response = _ref.data;
        if (response.code !== 10000) {
          _this2.$message.error(response.message || '请求失败');
          return;
        }
        _this2.departmentList = response.data;
      });
      this.dialog.permissionApply.visible = true;
    },
    departmentSelected: function departmentSelected(departmentId) {
      var _this3 = this;
      // 清除已选的角色
      this.dialog.permissionApply.form.roleIds = [];
      roleApi.getRolesByDepartmentId(departmentId).then(function (_ref2) {
        var response = _ref2.data;
        if (response.code !== 10000) {
          _this3.$message.error(response.message || '请求失败');
          return;
        }
        _this3.dialog.permissionApply.roleOptions = response.data;
      });
    },
    hasRole: function hasRole(roleId) {
      var roles = db.get('ROLES');
      var _iterator = _createForOfIteratorHelper(roles),
        _step;
      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var role = _step.value;
          if (role.id === roleId) {
            return true;
          }
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
      return false;
    },
    applyRole: function applyRole() {
      var _this4 = this;
      var permissionApplyForm = this.dialog.permissionApply.form;
      roleApi.applyRole(permissionApplyForm).then(function (_ref3) {
        var response = _ref3.data;
        if (response.code !== 10000) {
          _this4.$message.error(response.message || '请求失败');
          return;
        }
        _this4.$message.success('申请成功');
      });
      this.dialog.permissionApply.visible = false;
    }
  }
};