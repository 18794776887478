import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import _defineProperty from "/var/jenkins_home/workspace/zs-cloud-admin/node_modules/.pnpm/@babel+runtime-corejs2@7.24.5/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import request from "../request";
var cmsUserApi = {
  getCmsUserById: function getCmsUserById(cmsUserId) {
    return request.get("/system/cmsUsers/".concat(cmsUserId));
  },
  getCmsUserList: function getCmsUserList(queryParams) {
    return request({
      url: '/system/cmsUsers/list',
      method: 'post',
      data: queryParams
    });
  },
  listForSelect: function listForSelect(pageParam, key) {
    return request({
      url: '/system/cmsUsers/list-for-select',
      method: 'get',
      params: _objectSpread(_objectSpread({}, pageParam), {}, {
        key: key
      })
    });
  },
  // 芝士用户是否绑定了后台管理
  hasUserBoundCmsUser: function hasUserBoundCmsUser(userId) {
    return request.get("/system/cmsUsers/users/".concat(userId));
  },
  addCmsUser: function addCmsUser(userId, roleList, departmentId) {
    var cmsUser = {
      userId: userId,
      departmentId: departmentId,
      roleList: roleList,
      state: 1
    };
    return request.post("/system/cmsUsers", cmsUser);
  },
  deleteById: function deleteById(cmsUserId) {
    return request.delete("/system/cmsUsers/".concat(cmsUserId));
  },
  // 更新部门/角色/状态
  updateCmsUser: function updateCmsUser(cmsUserId, roleList, departmentId, status) {
    return request.put("/system/cmsUsers/".concat(cmsUserId), {
      id: cmsUserId,
      roleList: roleList,
      departmentId: departmentId,
      status: status
    });
  },
  // 根据部门id获取后台用户
  getByDepartmentId: function getByDepartmentId(departmentId) {
    return request.get("/system/cmsUsers/department/".concat(departmentId));
  },
  getByDepartmentBusinessKey: function getByDepartmentBusinessKey(departmentBusinessKey) {
    return request.get("/system/cmsUsers/department-business-key/".concat(departmentBusinessKey));
  },
  registerCmsUser: function registerCmsUser(registerForm) {
    return request.post("/system/cmsUsers/register", registerForm);
  },
  getRolesByCmsUserId: function getRolesByCmsUserId(cmsUserId) {
    return request.get("/system/cmsUsers/".concat(cmsUserId, "/roles"));
  }
};
export default cmsUserApi;