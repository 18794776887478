import _MessageBox2 from "element-ui/lib/theme-chalk/message-box.css";
import "element-ui/lib/theme-chalk/base.css";
import _MessageBox from "element-ui/lib/message-box";
import { createVersionPolling } from "./version-polling.esm";
/**
 * 插件文档：https://github.com/JoeshuTT/version-polling/tree/main
 */
var isShowUpdateModal = false; // 是否已经展示过更新的弹窗，已经展示的话就不再展示
createVersionPolling({
  appETagKey: '__APP_ETAG__',
  pollingInterval: 3 * 60 * 1000,
  // 轮询时间三分钟
  silent: process.env.NODE_ENV !== 'production',
  // 开发环境下不检测
  htmlFileUrl: location.origin + location.pathname + 'index.html',
  onUpdate: function onUpdate(self) {
    // 当检测到有新版本时，执行的回调函数，可以在这里提示用户刷新页面
    !isShowUpdateModal && _MessageBox.alert('当前后台管理系统有更新，如果您此时在页面中没有进行填写内容等操作，请点击【立即刷新】使用最新版本！', '版本更新提示', {
      showClose: false,
      showCancelButton: true,
      confirmButtonText: '立即刷新',
      cancelButtonText: '稍后刷新(3分钟后自动刷新)',
      callback: function callback(action, instance) {
        if (action === 'confirm') {
          // 立即刷新
          self.onRefresh();
          isShowUpdateModal = true;
        } else if (action === 'cancel') {
          // 3分钟后自动刷新
          isShowUpdateModal = true;
          setTimeout(function () {
            self.onRefresh();
          }, 3 * 1000 * 60);
        }
      }
    });
  }
});