import request from "../request";

// 部门表api
var departmentApi = {
  getDepartmentById: function getDepartmentById(departmentId) {
    return request.get("/system/departments/".concat(departmentId));
  },
  getDepartmentList: function getDepartmentList(queryParams) {
    return request.get("/system/departments", {
      params: queryParams
    });
  },
  getAllDepartments: function getAllDepartments() {
    return request.get("/system/departments/all");
  },
  deleteDepartmentById: function deleteDepartmentById(departmentId) {
    return request.delete("/system/departments/".concat(departmentId));
  },
  addDepartment: function addDepartment(department) {
    return request.post("/system/departments", department);
  },
  updateDepartment: function updateDepartment(department) {
    return request.put("/system/departments/".concat(department.id), department);
  }
};
export default departmentApi;