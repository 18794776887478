export default {
  namespaced: true,
  state: {
    userInfoMap: {}
  },
  mutations: {
    setUserInfoMap: function setUserInfoMap(state, userInfoMap) {
      state.userInfoMap = Object.assign({}, state.userInfoMap, userInfoMap);
    }
  }
};