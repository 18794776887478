import "core-js/modules/es6.regexp.split";
import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es6.regexp.constructor";
import "core-js/modules/es6.regexp.replace";
/**
 * Parse the time to string
 * @param {(Object|string|number)}
 * @param {string} cFormat
 * @returns {string}
 */

export function formateDate(time) {
  var fmt = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'yyyy-MM-dd HH:mm:ss';
  time = time ? new Date(time) : new Date();
  if (!(time instanceof Date)) throw new Error('传入数据不可转换为时间对象');
  var o = {
    'M+': time.getMonth() + 1,
    // 月份
    'd+': time.getDate(),
    // 日
    'H+': time.getHours(),
    // 小时
    'm+': time.getMinutes(),
    // 分
    's+': time.getSeconds(),
    // 秒
    'q+': Math.floor((time.getMonth() + 3) / 3),
    // 季度
    S: time.getMilliseconds() // 毫秒
  };
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (time.getFullYear() + '').substr(4 - RegExp.$1.length));
  }
  for (var k in o) {
    if (new RegExp('[^[](' + k + ')').test(fmt)) {
      // 使用方括号标识不需要格式化的字符 (如 [A] [MM])
      fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length));
    }
  }
  return fmt;
}
export function isNullOrEmpty(value) {
  return !!(value === null || value === '' || value === undefined);
}
export function isEmptyArray(arr) {
  return !!(Array.isArray(arr) && arr.length === 0);
}
export function truncate(value, num) {
  if (typeof value === 'string') {
    if (value.length <= num) {
      return value;
    } else {
      return value.slice(0, num);
    }
  } else {
    return value;
  }
}

/**
 * 获取包含从传入起始年份到当前年份的选项配置项数组
 * @param {String} startYear 起始年份
 * @returns
 */
export function generateYearOptions(startYear) {
  var currentYear = new Date().getFullYear();
  var yearOptions = [];
  for (var year = startYear; year <= currentYear; year++) {
    yearOptions.push({
      value: year,
      label: year.toString()
    });
  }
  return yearOptions;
}

/**
 * 获取文件链接的后缀名
 * @param {String} url 文件链接
 * @returns
 */
export function getFileExtension(url) {
  // 去除URL中的查询字符串（如果有的话）
  var path = url.split('?')[0];
  // 从最后一个点开始分割字符串，并取最后一部分
  var parts = path.split('.');
  // 第一个元素（现在位于数组的开头）是文件扩展名
  // 但需要排除空字符串的情况（如果URL以点结尾）
  var lastPart = parts[parts.length - 1];
  return (lastPart === null || lastPart === void 0 ? void 0 : lastPart.toLowerCase()) || null;
}