export default {
  namespaced: true,
  state: {
    manual: {
      // 当前解析手册学校
      universityName: '',
      // 当前解析手册学院
      collegeName: '',
      // 当前解析手册学院id
      collegeId: '',
      // 当前解析手册id
      id: ''
    },
    website: '',
    nowYear: 2023 // 年份硬编码，每年根据运营要求改一下
  },
  mutations: {
    // 赋值当前解析手册学
    setManual: function setManual(state, value) {
      state.manual = value;
    },
    // 赋值当前官网url
    setWebsite: function setWebsite(state, value) {
      state.website = value;
    },
    // 重置manual
    reset: function reset(state) {
      state.manual = {
        // 当前解析手册学校
        universityName: '',
        // 当前解析手册学院
        collegeName: '',
        // 当前解析手册学院id
        collegeId: '',
        // 当前解析手册id
        id: ''
      };
    }
  }
};