import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/es6.object.keys";
import "core-js/modules/web.dom.iterable";
import _defineProperty from "/var/jenkins_home/workspace/zs-cloud-admin/node_modules/.pnpm/@babel+runtime-corejs2@7.24.5/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";
import _Message2 from "element-ui/lib/message";
import _Message from "element-ui/lib/message";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var ZSMessage = function ZSMessage(options) {
  return setTimeout(function () {
    _Message(_objectSpread({}, options));
  });
};
['success', 'warning', 'info', 'error'].forEach(function (type) {
  ZSMessage[type] = function (options) {
    return setTimeout(function () {
      _Message({
        type: type,
        message: options
      });
    });
  };
});
['closeAll', 'close'].forEach(function (type) {
  ZSMessage[type] = function (options) {
    return setTimeout(function () {
      _Message[type](options);
    });
  };
});
export default ZSMessage;