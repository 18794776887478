import "core-js/modules/es6.regexp.to-string";
import CryptoJS from 'crypto-js';

// 加密函數
var encrypt = function encrypt(word) {
  var key = CryptoJS.enc.Utf8.parse('zszszszszszszszs');
  var enc = '';
  enc = CryptoJS.AES.encrypt(word, key, {
    iv: '',
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.ZeroPadding
  }).ciphertext.toString(CryptoJS.enc.Base64);
  return enc;
};
export { encrypt };