import { cloneDeep } from 'lodash';
export default {
  namespaced: true,
  state: {
    // 初始标签数据
    allTags: [],
    // 处理过后的标签数据
    initial: []
  },
  mutations: {
    // 赋值原始数据
    setallTags: function setallTags(state, value) {
      state.allTags = value;
    },
    // 赋值处理过后数据
    setInitial: function setInitial(state, value) {
      state.initial = cloneDeep(value);
    }
  }
};