import db from '@/utils/localstorage';
export default {
  namespaced: true,
  state: {
    accessToken: db.get('ACCESS_TOKEN'),
    refreshToken: db.get('REFRESH_TOKEN'),
    expireTime: db.get('EXPIRE_TIME', 0),
    user: db.get('USER'),
    permissions: db.get('PERMISSIONS'),
    routes: db.get('USER_ROUTER') || [],
    roles: db.get('ROLES') || []
  },
  mutations: {
    setAccessToken: function setAccessToken(state, val) {
      db.save('ACCESS_TOKEN', val);
      state.accessToken = val;
    },
    setRefreshToken: function setRefreshToken(state, val) {
      db.save('REFRESH_TOKEN', val);
      state.refreshToken = val;
    },
    setExpireTime: function setExpireTime(state, val) {
      db.save('EXPIRE_TIME', val);
      state.expireTime = val;
    },
    setUser: function setUser(state, val) {
      db.save('USER', val);
      state.user = val;
    },
    setRoles: function setRoles(state, val) {
      db.save('ROLES', val);
      state.roles = val;
    },
    setPermissions: function setPermissions(state, val) {
      db.save('PERMISSIONS', val);
      state.permissions = val;
    },
    setRoutes: function setRoutes(state, val) {
      db.save('USER_ROUTER', val);
      state.routes = val;
    }
  }
};