import Vue from 'vue';
import VueI18n from 'vue-i18n';
import zhLocale from "./zh";
Vue.use(VueI18n);
var messages = {
  zh: zhLocale
};
var i18n = new VueI18n({
  locale: 'zh',
  messages: messages
});
export default i18n;